<template>
  <Modal v-model="openBatchModal" @on-cancel="close" width="450" :title="title" :footer-hide="true" class="batchModal" :mask-closable="false">
    <div class="batchTitle"></div>
    <div style="text-align: center;margin-bottom: 30px;">
      <Upload :before-upload="handleUpload" ref="upload">
        <Button icon="ios-cloud-upload-outline">点击上传</Button>
      </Upload>
      <div class="fileName" v-if="file !== null" @mouseleave="mouseEnter = false" @mouseenter="mouseEnter = true">
        <div style="margin-right: 12px">文件名：{{ file.name }}</div>
        <svg
          v-if="mouseEnter"
          class="icon operaIcon"
          aria-hidden="true"
          style="cursor: pointer;"
          @mouseleave="mouseDeleteEnter = false"
          @mouseenter="mouseDeleteEnter = true"
          :style="mouseDeleteEnter ? 'color: rgb(90, 119, 214);' : ''"
          @click.stop="delFile"
        >
          <use xlink:href="#icon-guanbi1"></use>
        </svg>
      </div>
      <div style="padding: 15px 0"><a :href="getExcel">查看文档模板</a></div>
      <!--      <div><span style="color: red;font-size: 16px;" v-if="openTimeOut">(请先下载并仔细阅读格式规范{{timeOut}}s)</span></div>-->
    </div>
    <div class="footer">
      <Button class="modal-btn" @click="close">取消</Button>
      <Button type="primary" class="modal-btn" :loading="loading" @click="ok">确认</Button>
    </div>
  </Modal>
</template>

<script>
import examApi from '@api/exam'
import config from '../../config/config'
import { axios } from '../../api'
export default {
  name: 'batchAddModal',
  props: {
    IsExamOrCandidate: {
      type: Boolean, // (true candidate, false exam)
      default: false
    },
    title: {
      type: String,
      default: '导入'
    },
    openBatchModal: {
      type: Boolean,
      default: false
    },
    openTimeOut: {
      type: Boolean,
      default: false
    },
    timeOut: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      mouseDeleteEnter: false,
      mouseEnter: false,
      file: null,
      loading: false,
      deletePrompt: false
    }
  },
  computed: {
    getExcel() {
      return this.IsExamOrCandidate === false ? examApi.getCandidateExcelDesc : examApi.getExamExcelDesc
    }
  },
  methods: {
    handleUpload(file) {
      if (file.name.substring(file.name.indexOf('.'), file.name.length) === '.xls') {
        this.file = file
        let formData = new FormData()
        formData.append('file', this.file)
        this.IsExamOrCandidate
          ? examApi.uploadCandidate(formData).then(res => {
              if (res.res) {
                this.$message.success('导入成功!')
              }
            })
          : examApi.uploadExam(formData, this.$route.query.examId).then(res => {
              let examName = this.$route.query.examName
              if (res.res) {
                this.$message.success('导入至' + examName + '成功!')
              }
            })
      } else {
        this.$message.warning('请上传excel格式文档')
      }
    },
    delFile() {
      this.file = null
      this.$message.success('删除成功')
    },
    close() {
      this.$emit('on-cancel')
    },
    ok() {
      this.loading = true
      if (this.file !== null) {
        setTimeout(() => {
          this.$emit('on-ok')
          this.loading = false
          this.file = null
        }, 1500)
      } else {
        this.loading = false
        this.$message.warning('请上传文件')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../theme/variables';
.batchModal {
  .batchTitle {
    text-align: left;
    font-size: 26px;
  }
  .fileName {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer {
    text-align: center;
    margin-bottom: 20px;
    .modal-btn:first-child {
      margin-right: 14px;
    }
  }
}
div ::v-deep .operaIcon {
  width: 8px;
  height: 10px;
}
</style>
